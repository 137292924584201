
import { defineComponent } from "vue";
import ValidatePopup from "@/components/ValidatePopup.vue";
import Swiper, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import utilities from "../utilities";
import Toast from "@/components/Toast.vue";
import '@fortawesome/fontawesome-free/css/all.css';

interface ProductDimensions {
  height?: string;
  width?: string;
  length?: string;
  diameter?: string;
}

interface ProductSpecification {
  label: string;
  value: string;
}

interface ProductVariation {
  id: string | number;
  status: string;
  price: number;
  discount: number | null;
  stock: number;
  image: {
    data: {
      thumbnails: Array<{
        url: string;
      }>;
    };
  };
}

interface Product {
  id: string | number;
  name: string;
  short_description?: string;
  description: string;
  specifications?: ProductSpecification[];
  dimensions?: ProductDimensions;
  legalInfo?: string;
  no_stock?: boolean;
  selectedVariation: ProductVariation;
  variations: ProductVariation[];
  gallery: Array<{
    id: string | number;
    files_id: {
      data: {
        thumbnails: Array<{
          url: string;
        }>;
      };
    };
  }>;
}

export default defineComponent({
  name: "ProductView",
  components: {
    Toast,
    ValidatePopup,
  },
  data() {
    return {
      product: null as Product | null,
      loading: true,
      showValidatePopup: false,
      validated: false,
      showFullDescription: false,
    };
  },
  computed: {
    hasLongDescription(): boolean {
      return !!this.product?.description && !!this.product?.short_description;
    },
    showOriginalPrice(): boolean {
      return (
        (this.product?.selectedVariation?.discount != null && this.product?.selectedVariation?.discount > 0) ||
        (this.validated && this.$store.state.flags.descuentoVerificado)
      );
    }
  },
  async created() {
    try {
      const productId = this.$route.params.productId;
      const variationId = this.$route.params.variationId;

      // Fetch product data
      const productData = await utilities.getItems(
        "products/" + productId + "?fields=*,gallery.*.*,variations.*.*"
      ) as Product;

      this.product = productData;
      
      // Set default variation
      if (this.product?.variations?.length > 0) {
        this.product.selectedVariation = this.product.variations[0];

        // Set specific variation if provided
        if (variationId) {
          const selectedVariation = this.product.variations.find(
            (v: ProductVariation) => v.id == variationId
          );
          if (selectedVariation) {
            this.product.selectedVariation = selectedVariation;
          }
        }
      }

      // Initialize Swiper
      setTimeout(() => {
        new Swiper(this.$refs.gallery as HTMLElement, {
          slidesPerView: 1,
          spaceBetween: 0,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          modules: [Autoplay, Navigation, Pagination],
        });
      }, 100);

    } catch (error) {
      console.error('Error loading product:', error);
    } finally {
      this.loading = false;
    }
  },
  async mounted() {
    const toast = this.$refs.toast as InstanceType<typeof Toast>;
    if (this.$route.query["validation"]) {
      toast.show("Credencial validada!", "ok");
    }
    this.validated = (await utilities.isValidated()).isValid;
  },
  methods: {
    setVariation(variationIndex: number): void {
      if (this.product?.variations[variationIndex].status === "published") {
        this.product.selectedVariation = this.product.variations[variationIndex];
      }
    },
    formatPrice(price: number): string {
      return price?.toLocaleString('es-AR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || '0.00'
    },
    calculateFinalPrice(): number {
      if (!this.product?.selectedVariation) return 0;
      
      const price = this.product.selectedVariation.price;
      const discount = this.product.selectedVariation.discount || 0;
      const verifiedDiscount = this.validated && this.$store.state.flags.descuentoVerificado ? 5 : 0;

      const totalDiscount = discount + (this.validated ? verifiedDiscount : 0);
      return price - (totalDiscount / 100 * price);
    },
    getDiscount(): number | null {
      if (!this.product?.selectedVariation) return null;
      
      const discount = this.product.selectedVariation.discount || 0;
      const verifiedDiscount = this.validated && this.$store.state.flags.descuentoVerificado ? 5 : 0;

      const totalDiscount = discount + (this.validated ? verifiedDiscount : 0);
      return totalDiscount > 0 ? totalDiscount : null;
    },
    async addProduct(product: Product): Promise<void> {
      this.loading = true;
      const toast = this.$refs.toast as InstanceType<typeof Toast>;
      
      try {
        if (product.no_stock) {
          toast.show("Lo sentimos, este producto no está disponible", "");
          return;
        }

        if (
          product.selectedVariation?.status === "published" ||
          product.selectedVariation?.stock > 0
        ) {
          this.$store.commit("addProduct", product);
          toast.show("Agregaste un producto al carrito", "");
        } else {
          toast.show("Color no disponible", "");
        }
      } catch (error) {
        console.error('Error adding product:', error);
        toast.show("Error al agregar el producto", "");
      } finally {
        this.loading = false;
      }
    },
    goBack(): void {
      this.$router.go(-1);
    },
  },
});
